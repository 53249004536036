.container {
  margin-top: 1rem;
}

.recipient_container {
  // background-color: #f5f5f5;
  // padding: 1rem 2rem;
  padding: 1rem 0.5rem 0rem 0.5rem;
}

.attachment_image {
  height: 60px;
  padding: 0 10px;
}

.attachment_delete_button {
  margin-left: 40px;
}

:global .ck-editor__editable_inline {
  min-height: 250px;
}

.copy_button {
  margin-top: 1em;
  margin-bottom: 1em;
}

.copyLink {
  cursor: pointer;
  margin-left: auto;
  color: white;

  &:hover {
    color: yellow;
  }
}

.formError {
  color: red;
}

.overlayContainer {
  :global ._loading_overlay_content {
    position: relative;
    top: 20%;
    left: 40%;
    margin: 0 !important;
  }
}

label {
  margin-bottom: 8px;
}
