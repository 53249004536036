@font-face {
  font-family: "Basis Grotesque";
  src: url("../../fonts/Basis\ Grotesque/basis-grotesque-regular.eot");
  src: url("../../fonts/Basis\ Grotesque/basis-grotesque-regular.eot") format("embedded-opentype"),
    url("../../fonts/Basis\ Grotesque/basis-grotesque-bold.eot") format("embedded-opentype"),
    url("../../fonts/Basis\ Grotesque/basis-grotesque-regular.woff2") format("woff2"),
    url("../../fonts/Basis\ Grotesque/basis-grotesque-bold.woff2") format("woff2"),
    url("../../fonts/Basis\ Grotesque/basis-grotesque-regular.woff") format("woff"),
    url("../../fonts/Basis\ Grotesque/basis-grotesque-bold.woff") format("woff"),
    url("../../fonts/Basis\ Grotesque/basis-grotesque-medium.ttf") format("truetype"),
    url("../../fonts/Basis\ Grotesque/basis-grotesque-regular.ttf") format("truetype"),
    url("../../fonts/Basis\ Grotesque/basis-grotesque-bold.ttf") format("truetype");
}

* {
  font-family: "Basis Grotesque", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

/*  */
/*  */
:root {
  /* Colors */
  --red: red;
  --blue: #44b0ff;
  --white: #ffffff;
  --green: #067225;
  --danger: red;
  --gray: #e0e0e0;
  --darkgray: #9e9e9e;
  --grey: #00000099;
  --darkergray: #707070;
  --black: #000000de;
  --main: #192f54;
  --accent: #365a8d;
  --inactive: #c3c9d3;
  --light-blue: #44b0ff1f;
  --alice-blue: #f2f7fe;
  --spicy-pink: #df1660;

  /* Colors */
  --main-color: var(--main);
  --input-color: var(--black);
  --colors-paragraph: var(--black);
  --colors-header: var(--black);
  --colors-link: var(--accent);
  --colors-success: var(--main);
  --colors-danger: var(--red);
  --colors-accent: var(--accent);
  --color-main: var(--main);
  --color-dashboard-bkground: var(--white);

  /* Fonts */
  --fonts-paragraph: 16px;
  --fonts-span: var(--fonts-paragraph);

  /* Sizes */
  --xxs: 3px;
  --xs: 8px;
  --sm: 12px;
  --md: 16px;
  --lg: 22px;
  --xl: 30px;
  --xxl: 45px;

  /* Input groups */
  --input-group-margin-bottom: 15px;

  /* Input field variables */
  --input-color: var(--grey);
  --input-error: var(--red);
  --input-help: var(--blue);
  --input-border-width: 1px;
  --input-padding-x: 10px;
  --input-padding-y: 10px;
  --input-border-color: #0000001f;
  --input-border-size: 1px;
  --input-border-error: var(--danger);
  --input-border-radius: 3px;
  --input-placeholder-color: var(--grey);

  /* Width utils */
  --w-100: 100%;
  --w-100: 50%;
  --w-vw: cal(vw);

  --w-100: 100%;
  --w-100: 50%;
  --h-vh: cal(vh);
}

html,
main,
body,
#root {
  width: 100%;
  height: 100%;
  overflow: auto;
}
